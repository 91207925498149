import "@hotwired/turbo-rails"
import "./toast"

// import "bootstrap"
import "bootstrap/js/dist/alert"
import "bootstrap/js/dist/button"
import "bootstrap/js/dist/carousel"
import "bootstrap/js/dist/collapse"
import "bootstrap/js/dist/offcanvas"
import "bootstrap/js/dist/dropdown"
import "bootstrap/js/dist/modal"
// import "bootstrap/js/dist/popover"
// import "bootstrap/js/dist/scrollspy"
import "bootstrap/js/dist/tab"
// import "bootstrap/js/dist/toast"
// import "bootstrap/js/dist/tooltip"

import Rails from "@rails/ujs"
Rails.start()

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import "./activestorage/direct_upload"

import LocalTime from "local-time"
LocalTime.start()
window.LocalTime = LocalTime

import "chartkick/chart.js"

import "form-request-submit-polyfill" // apparently Safari does not support requestSubmit() on forms

import "@rails/actiontext"

import Trix from "trix"
Trix.config.textAttributes.sup = { tagName: "sup", inheritable: true }
Trix.config.textAttributes.sub = { tagName: "sub", inheritable: true }

addEventListener("trix-initialize", function (event) {
  var buttonHTML, buttonGroup

  buttonHTML = "<button type=\"button\" data-trix-attribute=\"sup\"><sup>SUP</sup></button>"
  buttonHTML += "<button type=\"button\" data-trix-attribute=\"sub\"><sub>SUB</sub></button>"

  buttonGroup = event.target.toolbarElement.querySelector(".trix-button-group--text-tools")
  buttonGroup.insertAdjacentHTML("beforeend", buttonHTML)
})

// Setup Stimulus
import "./controllers"

import "./stripe"
